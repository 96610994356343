@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&family=Raleway:wght@400;700&display=swap);
header, footer, main { max-width: 480px; }
header, footer { text-align: center; }
footer { border-top: 1px solid #ddd; padding: 1em; }
main { flex-grow: 1; padding: 1em; }

html, body { margin: 0; padding: 0; font-family: Raleway, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
#root { display: flex; flex-direction: column; align-items: center; min-height: 100vh; }
.title { font-family: "Playfair Display", serif; font-weight: 600; font-size: 2em; color: #3eb489; }
.emoji { display: block; font-size: 2em; margin-bottom: .2em; }
button { display: block; margin: .5em auto; box-sizing: border-box; width: 70%; padding: .5em; border: none; background-color: #3eb489; color: #fff; font-weight: bold; }
button.secondary { border: 1px solid #3eb489; background: #fff; color: #3eb489; }
button.sending { opacity: .8; }

